import { AuthenticatedTemplate } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import { Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';

// Ant Design Components
import { Row, Col, Form, Input, Select, Button, Typography, message } from 'antd';
const { Title } = Typography;
const { Paragraph } = Typography;

import { MaskedInput } from "antd-mask-input";

// CSV Parser
var csv = require('jquery-csv');

export const Home = () => {
    // MSAL (Authentication)
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    // Ant Design Form
    const [smsForm] = Form.useForm();

    // Ant Design Message
    const [messageApi, contextHolder] = message.useMessage();
    const key = 'updatable';
    // Success
    const successMessage = () => {
      messageApi.open({
        key,
        type: 'loading',
        content: 'Sending...',
      });
      setTimeout(() => {
        messageApi.open({
          key,
          type: 'success',
          content: 'Sent!',
          duration: 2,
        });
      }, 1000);
    };
    // Fail
    const failMessage = () => {
        messageApi.open({
          key,
          type: 'loading',
          content: 'Sending...',
        });
        setTimeout(() => {
          messageApi.open({
            key,
            type: 'error',
            content: 'SMS Failed to Send!',
            duration: 2,
          });
        }, 1000);
      };

    // On Form Submission
    const onSMSFormFinish = async (values) => {
        let paBody = values
        paBody.user = activeAccount.username
        var requestOptions = {
            method: 'POST',
            body: JSON.stringify(paBody),
            headers: {
                "Content-Type": "application/json",

              },
          };
        var sms = await fetch('https://prod-02.australiasoutheast.logic.azure.com:443/workflows/bc1d10f5c4764b07a136b75df430f34e/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=nEqyuZBchqYsckxw8_v76YasSRPsenaZpkDqe-b0iNw', requestOptions)
        if(sms.status === 202){
            // Success
            successMessage()
        } else {
            // Error Handling
            failMessage()
        }

    }

    // Template Selection Change
    const templateChange = (value) => {
        let selectedTemplate = templates.find(o => o.id === value)
        smsForm.setFieldsValue({smsMessage: selectedTemplate.message})
    }

    // Field States
    const [ smsMessage, setSMSMessage] = useState()
    const [ templates, setTemplates ] = useState()

    // Retrieve Template CSV
    const retrieveTemplates = async () => {
        var templateURL = "https://dev.azure.com/MorrisonsLaw/17147e6d-0231-4367-bb6f-09d33cf59cb3/_apis/git/repositories/97c733b2-3c9e-4d5e-8524-8d46397e35a5/items?path=/Send%20SMS%20Portal%20-%20Templates.csv&versionDescriptor%5BversionOptions%5D=0&versionDescriptor%5BversionType%5D=0&versionDescriptor%5Bversion%5D=main&resolveLfs=true&%24format=octetStream&api-version=5.0&download=true"
        var templateResponse = await fetch(templateURL)
        if(templateResponse.status === 200){
            var templateData = await templateResponse.text() 
            await setTemplates(csv.toObjects(templateData))
        }   
    }

    // On Load
    useEffect(() => {
        retrieveTemplates()
    }, [])

    return (
        <>
            <AuthenticatedTemplate>
                <Row>
                    <Col span={12} offset={6}>
                        <Form name="SMSForm" size='large' form={smsForm} onFinish={onSMSFormFinish}>
                            <Form.Item label="Phone Number" name="phoneNumber" rules={[{ required: true, message: 'Please enter a Phone Number!'}, {pattern: /^614[0-9]{8}$/, message: 'Incorrect Number Format!'}]}>
                                <MaskedInput mask={'61400000000'}/>
                            </Form.Item>
                            <Form.Item label="Template" name="template">
                                <Select onChange={templateChange}>
                                    { templates ? templates.map((item, index) => ( 
                                        <Select.Option key={index} value={item.id}>{item.label}</Select.Option>
                                    )) : null }
                                </Select>
                            </Form.Item>
                            <Form.Item label="SMS Message" name="smsMessage" rules={[{ required: true, message: 'Please enter a message!'}]}>
                                <Input.TextArea rows={8} />
                            </Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form>
                        {contextHolder}
                    </Col>
                </Row>
            </AuthenticatedTemplate>
        </>
    );
};