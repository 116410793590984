import { useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
// import { Nav, Navbar, Button, Dropdown, DropdownButton } from 'react-bootstrap';

import { loginRequest } from '../authConfig';
import { clearStorage } from '../utils/storageUtils';

// Logo
import logo from '../images/logo.png';

// Ant Design Components
import { Typography, Col, Row, Button, Dropdown, Space } from 'antd';
const { Title } = Typography;
const { Paragraph } = Typography;

// Responsive Design
import { useMediaQuery } from 'react-responsive'

export const NavigationBar = () => {

    const menuItemsAuthenticated = [
        {
          key: '1',
          label: (
            <a href="/profile">
              Profile
            </a>
          ),
        },
        {
          key: '2',
          label: (
            <a href="/contact">
              Contact
            </a>
          ),
        }
    ];

    const [showProfilePicker, setShowProfilePicker] = useState(false);
    const { instance } = useMsal();

    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    const handleLoginRedirect = () => {
        /**
         * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page 
         * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
         * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations 
         */

        instance.loginRedirect(loginRequest)
            .catch((error) => console.log(error));
    };

    const handleLoginPopup = () => {
        instance.loginPopup({
            ...loginRequest,
            redirectUri: '/redirect'
        }).catch((error) => console.log(error));
    };

    const handleLogoutRedirect = () => {
        let account = instance.getActiveAccount();
        clearStorage(account);

        instance.logoutRedirect({
            account: instance.getActiveAccount(),
        });
    };

    const handleLogoutPopup = () => {
        let account = instance.getActiveAccount();
        clearStorage(account);

        instance.logoutPopup({
            mainWindowRedirectUri: '/', // redirects the top level app after logout
            account: instance.getActiveAccount(),
        });
    };

    const handleSwitchAccount = () => {
        setShowProfilePicker(!showProfilePicker);
    };

    const screenSize = useMediaQuery({ query: '(min-width: 1000px)' })

    /**
     * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
     * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
     * only render their children if a user is authenticated or unauthenticated, respectively.
     */
    return (
        <>
            {screenSize ?
                <Row className='navbarStyle' justify="space-around" align="middle">
                    <Col span={8} style={{display: 'flex', justifyContent: 'flex-start'}}>
                        <a href="/"> 
                            <img src={logo} alt="Logo" className="navbarLogo"/>
                        </a>
                    </Col>
                    <Col span={8} style={{display: 'flex', justifyContent: 'center'}}>
                        <Title>Send SMS Portal</Title>
                    </Col>
                    <Col span={8} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <AuthenticatedTemplate>
                            <Dropdown placement="bottomRight" menu={{ items: 
                                [
                                    // {
                                    //     key: '1',
                                    //     label: (
                                    //         <a style={{textDecoration: 'none'}} href="/profile">
                                    //             Profile
                                    //         </a>
                                    //     ),
                                    // },
                                    // {
                                    //     key: '2',
                                    //     label: (
                                    //         <a style={{textDecoration: 'none'}} href="/contact">
                                    //             Contact
                                    //         </a>
                                    //     ),
                                    // },
                                    {
                                        key: '1',
                                        label: (
                                            <a onClick={handleLogoutRedirect}>
                                                Sign Out
                                            </a>
                                        ),
                                    }
                                ]
                            }}>
                                <Button>{activeAccount ? activeAccount.name : 'Unknown'}</Button>
                            </Dropdown>
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <Button onClick={handleLoginRedirect}>Sign In</Button>
                        </UnauthenticatedTemplate>
                    </Col>
                </Row>
            :
            <>
                <Row className='navbarStyle' justify="space-around" align="middle">
                    <Col span={4} style={{display: 'flex', justifyContent: 'flex-start'}}>
                        <a href="/"> 
                            <img src={logo} alt="Logo" className="navbarLogo"/>
                        </a>
                    </Col>
                    <Col span={20} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Title>Send SMS Portal</Title>
                    </Col>
                </Row>
                <Row className='navbarStyle' justify="space-around" align="middle">
                    <Col span={24} style={{display: 'flex', justifyContent: 'center'}}>
                        <AuthenticatedTemplate>
                            <Dropdown placement="bottomRight" menu={{ items: 
                                [
                                    // {
                                    //     key: '1',
                                    //     label: (
                                    //         <a style={{textDecoration: 'none'}} href="/profile">
                                    //             Profile
                                    //         </a>
                                    //     ),
                                    // },
                                    // {
                                    //     key: '2',
                                    //     label: (
                                    //         <a style={{textDecoration: 'none'}} href="/contact">
                                    //             Contact
                                    //         </a>
                                    //     ),
                                    // },
                                    {
                                        key: '1',
                                        label: (
                                            <a onClick={handleLogoutRedirect}>
                                                Sign Out
                                            </a>
                                        ),
                                    }
                                ]
                            }}>
                                <Button>{activeAccount ? activeAccount.name : 'Unknown'}</Button>
                            </Dropdown>
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <Button onClick={handleLoginRedirect}>Sign In</Button>
                        </UnauthenticatedTemplate>
                    </Col>
                </Row>
            </>
            }
            
        </>
    );
};
